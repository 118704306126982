import React, {Component} from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';

import '../styles/menuBar.scss';

import Header from './ui/Header';
import Footer from './ui/Footer';
import SideBar from './ui/SideBar';
import MenuBar from './ui/MenuBar'; 

import {nav} from '../data/nav';
import FadeIn from './effects/FadeIn';
import ImageViwer from './ui/ImageViewer';
import makeCall, { R_ID } from '../data/makeCall';
import parseGalleryData from '../data/galleryData'

class App extends Component{
  constructor(){
    super();
    this.state=({
      sideBar: false,
      menuBar: false,
      viewImage: null,
      galleryData: [],
      galleryLoaded: false,
      skillsData: {}
    });
  } 
  toggle = (target, status) => { 
    this.setState({
      [target]: status || !this.state.sideBar,
      render: true
    })
  }
  
  detectScroll = ()=>{
    let pageY = window.pageYOffset; 
    return pageY > 850 ? true: false ;
  }

  detectScreenWidth = () =>{
    let pageWidth = window.innerWidth; 
      return pageWidth < 750 ? true : false ;
  };

  detectScreenSize = () =>{
    let showMenu;
    if(this.detectScroll()){
      showMenu = true;
    }else{
      showMenu = this.detectScreenWidth();
    }

    if(showMenu !== this.state.menuBar){
      this.setState({ menuBar: showMenu });
    }
  }

  escKeyClose = event => {
    if(event.keyCode === 27) this.setViewImage(null)
  }

  loadGalleryData = async () => {
    const reRoute = '/a1-api/site/resources/'
    Promise.all([
      makeCall({ url: `${reRoute}objects-list/${R_ID}/06d/` }),
      makeCall({ url: `${reRoute}gallery_images/${R_ID}/` })
    ]).then(resp => {
      const [apiData, imgData] = resp
      const galleryData = apiData.data.find(g => g.title === 'gallery')
      const skillData = apiData.data.find(g => g.title === 'skillSet')
      const data = JSON.parse(galleryData.object)
      const skillObject = JSON.parse(skillData.object)
      if (galleryData && imgData && imgData.data) {
        const parseData = parseGalleryData(data, imgData.data)
        const useSkillsData = skillObject && skillObject.length > 0
          && skillObject[0]
        this.setState({
          galleryData: parseData,
          galleryLoaded: true,
          skillsData: useSkillsData
        })
        if (sessionStorage) {
          sessionStorage.setItem('galleryData', JSON.stringify(parseData))
          useSkillsData &&
            sessionStorage.setItem('skillsData', JSON.stringify(useSkillsData))
        }   
      }
    })
  }

  componentDidMount(){
    document.addEventListener("scroll", this.detectScreenSize);
    window.addEventListener("resize", this.detectScreenSize);
    window.addEventListener('keydown', this.escKeyClose)
    this.detectScreenSize();
    if (!this.state.galleryLoaded) {
      const getItem = sessionStorage.getItem('galleryData')
      const getSkillsItem = sessionStorage.getItem('skillsData')
      if (getItem) {
        try {
          this.setState({
            galleryData: JSON.parse(getItem),
            skillsData: JSON.parse(getSkillsItem),
            galleryLoaded: true
          })
        } catch {
          this.loadGalleryData()
        }
      } else this.loadGalleryData()
    }
  }

  componentWillUnmount(){
    window.removeEventListener('keydown', this.escKeyClose)
  }

  scrollPage = (e, redirect = false) => {
    this.setState({sideBar: false});
    const content = document.getElementById('main-content-section-marker')
    if (content) {
      return content.scrollIntoView({behavior: 'smooth'})
    }
  }

  setViewImage = image => this.setState({viewImage: image || null})

  render(){
    const {sideBar, menuBar, viewImage} = this.state;
    const galleryData = this.state.galleryData
      ? [...this.state.galleryData].sort((a, b) => {
        if (a.index && b.index) {
          return a.index - b.index
        }
        return -1
      })
      : []
    return (
      <div className="App">
        <Router> 
          <FadeIn>
            { sideBar && <SideBar nav={nav} toggle={this.toggle} scrollPage={this.scrollPage} /> }
          </FadeIn>
          <FadeIn>
            { menuBar && <MenuBar nav={nav} toggle={this.toggle} scrollPage={this.scrollPage} /> }
          </FadeIn>
          <Header nav={nav} scrollPage={this.scrollPage} /> 
          <main>
            <div id='main-content-section-marker' className='position-scroll-marker' />
            {
              nav.map(
                (link, i) => {
                  return(
                    i === 0 ? 
                      <React.Fragment key={i} >
                        <Route exact path="/" render={()=> 
                          <link.component setViewImage={this.setViewImage} galleryData={galleryData} />}
                        />
                        <Route path={link.path} render={()=>
                          <link.component setViewImage={this.setViewImage} galleryData={galleryData} />}
                        />
                      </React.Fragment>
                      :
                      <React.Fragment key={i} >
                        <Route
                          path={link.path}
                          render={() => <link.component skillsData={this.state.skillsData} />}
                        />
                      </React.Fragment>
                      )
                  }
                )
            }
            <Footer />
          </main>
        </Router>
        {viewImage && 
          <FadeIn>
            <ImageViwer
              viewImage={viewImage}
              setViewImage={this.setViewImage}
              galleryData={galleryData}
            />
          </FadeIn> }
      </div>
    ); 
  }
}

export default App;
