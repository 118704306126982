import React from 'react';

const Logo = ({color, width, height}) => {
    return (
        <svg  
            version="1.1" 
            xmlns="http://www.w3.org/2000/svg" 
            x="0px" 
            y="0px" 
            width={width || "503.5px"}
            height={height || "82.8px"} 
            viewBox="0 0 503.5 82.8" 
            style = {{fill: color || "darkgray"}}
        >  
        <g>
                <path d="M99.8,59.1C94,42.1,88.2,34,82.4,34c-1.8,0-5.8,3.6-11.2,11.2c0-0.4,0-0.4,0-0.9h-1.3c-0.9,0-1.3,0.4-1.3,2.2
                    h-0.9v-4.5c0.4-17.5,3.6-29.1,9-34V6.7c-1.3-3.6-3.1-5.4-5.4-5.4h-2.7c-3.6,8.1-6.3,21-8.1,38.5c0,2.7,0,5.4,0.4,7.6
                    c-0.9,0.4-1.8,0.4-2.7,0.4c-17,3.1-28.6,4.9-35.4,4.5L17.5,51c-3.6-0.4-6.3-1.8-8.1-4v-1.3c0.4-4.5,2.2-9,5.8-13.4
                    c3.1-3.1,4.5-5.8,4.9-9.4c1.8,0,2.7-0.9,3.1-2.7h1.3l-3.1,3.6v0.9l8.1-6.7V17l-0.4-0.9l1.8,0.9c2.7-2.2,6.3-4,10.7-5.4v0.9
                    c2.7-0.4,4-0.9,4.5-1.8l0.4-4.5l-0.4-0.9l-1.3,0l-3.1-4.5c-4.5,0.4-9,2.2-13.9,6.7C15.7,14.8,6.3,25.5,0,39.8
                    C-0.4,50.6,5.4,57.3,17.9,60l9.4-1.8v0.9l12.1-2.2h0.9l0.4,0.9l4.5-1.8h2.2l0.4,0.9l1.8-3.6h0.9l0.4,0.9c3.6,0,5.4-0.4,5.8-1.8
                    l0.9,0.9l5.4-1.3c1.3,3.6,3.1,6.3,5.4,7.6l2.7-0.9c6.3-8.5,10.3-13.4,12.1-14.8h1.8l1.8,0.9c6.7,17.9,13,26.4,17.9,26.4h2.2
                    l3.6-3.6v-0.9C105.6,64.9,102.1,62.7,99.8,59.1z M18.4,23.7h0.9c-2.7,4-5.4,6.7-6.7,7.6C12.6,30,14.3,27.3,18.4,23.7z M11.7,31.8
                    v2.7l-1.8-1.3L11.7,31.8z M68.5,47.4c0.4,0,0.4,0,0.9,0l-0.4,0.4L68.5,47.4z"/>
                <path d="M119.1,31.8c-3.1,0-6.7,5.8-10.7,17.5h-0.4c-0.4-8.1-1.8-12.1-4-12.1h-1.8c-1.3,0-1.8,1.8-1.8,5.8v2.2
                    c0,6.3,1.8,11.2,5.4,14.8h1.8l4-0.4l9-20.1l2.2,0.4c0.9,0,1.3-0.9,2.2-2.7C124.4,34,122.6,32.2,119.1,31.8z"/>
                <path d="M133.8,37.6c-0.9-2.7-1.8-4.5-2.7-4.5h-2.7c-0.9,0-1.8,2.7-1.8,8.5v4.9c0,9,2.2,13,6.7,13h1.8l1.8-1.8v-1.3
                    c-2.2-3.6-3.1-6.7-3.1-9.8L133.8,37.6z"/>
                <path d="M130.7,10.7c2.2,0,3.6-0.4,4-1.8V7.2c0-3.1-1.3-4.5-4.5-4.5h-1.8c-0.9,0-1.3,1.3-1.8,4
                    C128,9,129.4,10.7,130.7,10.7z"/>
                <path d="M145.5,40.3l3.6-1.8h0.4l0.4,0.4v-0.4l0.4,0.4l1.3-0.4h0.4l0.4,0.4h0.4l0.4-0.4l4.9,0.9l1.8-0.4
                    c0-4-2.2-6.3-6.3-6.3h-4.5c-6.7,0-10.7,1.3-12.1,4.5c0.4,3.1,3.1,7.2,8.1,12.1l-2.2,3.1v0.4c0,4.5,4.9,6.7,14.3,6.7l2.2-1.8
                    c0-1.3-0.9-3.6-2.7-5.8C149.5,45.6,145.5,41.6,145.5,40.3z M150.8,53.3l-0.9-0.4l-0.4-0.9v-0.4l3.1,2.2L150.8,53.3z"/>
                <path d="M206.3,33.1c-5.8,0-9,2.2-9.4,7.2c0-2.2-4.9-3.1-13.9-3.1l-6.7,0.4l-0.9-2.2c-1.8-13-3.6-19.2-4.9-19.2h-2.2
                    l-0.9,1.3v2.2c0,3.6,0.9,9.4,3.1,17.5v1.3c-7.2,2.7-11.2,5.8-11.2,8.5c0,0.9,0.9,2.7,2.2,4.5c5.8,5.4,10.7,8.1,15.2,8.1
                    c1.8-0.4,3.1-1.3,4-3.6c0-2.2-1.3-7.2-3.6-13.9l11.6-1.3v1.3h1.3l0.4-0.4v0.4l1.8-0.4h0.4l0.4,0.4l1.8-1.3l0.4,0.4l0.9-0.9
                    c0,0.4,0,0.9,0,0.9c0.4,5.4,1.3,8.1,2.2,8.1c3.1,6.7,7.2,10.3,12.1,10.3c4,0,8.5-2.7,13.9-8.5C225.1,42.1,218.9,36.2,206.3,33.1z
                    M175,52.8c-1.3,0-4-1.3-7.2-3.6v-0.4c0.9-0.9,1.8-2.2,1.8-3.6l2.7-1.3h0.9c0.9,0,1.8,2.7,3.1,8.5H175V52.8z M210.8,54.6
                    c-2.7-0.9-5.4-4.5-7.6-9.8l2.7,4.5l-2.2-7.6c0-1.8,1.3-2.7,4-2.7c6.7,1.3,10.7,4.5,11.2,9.4C218.9,50.6,216.2,52.4,210.8,54.6z"/>
                <path d="M244.4,32.7c-12.1,0-17.9,11.2-17.9,33.6c0,2.2,0.4,4.9,1.3,8.1c2.2,5.8,6.3,8.5,11.6,8.5
                    c2.2-1.3,3.1-2.2,3.1-3.1c0-4.5-0.4-11.6-1.8-21.5v-0.4l1.3,0.9h1.3l0.4-0.4l0.4,0.4c7.2-4.5,10.7-9.4,10.7-14.3v-0.9
                    C254.7,37.6,251.1,34.5,244.4,32.7z M248.8,44.3c0,2.2-2.7,5.8-8.1,10.3c-0.4,0-0.9-0.4-0.9-1.8c0-4-1.3-6.7-3.6-7.6h-0.4l-0.4,0.4
                    l-0.4-0.4h-0.4l-1.3,1.3c2.2,14.8,3.1,24.2,3.1,28.6l0.4,1.3l-0.4,0.4c-3.1-0.4-5.4-5.8-6.3-16.1l0.4-0.4V60l-0.4-0.4l0.4-0.4v-0.9
                    l-0.4-0.4l0.4-0.4v-3.1l-0.4-0.4h0.4c2.7-10.3,6.7-15.2,12.5-15.2c2.7,0,4.5,0.9,5.8,3.1V44.3z"/>
                <path d="M295.8,59.1C290,42.1,284.2,34,278.4,34c-2.2,0-6.7,4.5-13.9,13.9l-1.3-1.3v-4.9c0.4-17.5,3.6-29.1,9-34V6.3
                    c-1.3-3.6-3.1-5.4-5.4-5.4h-2.7c-3.6,8.1-6.3,21-8.1,38.5c0,10.7,2.2,17.5,6.7,20.1l2.7-0.9C273,48.3,277.5,43,278.8,43l2.7,1.3
                    c6.7,17.9,13,26.4,17.9,26.4h2.2l3.6-3.6v-0.9C301.2,64.9,298.1,62.7,295.8,59.1z"/>
                <path d="M342.4,31.8c-3.1,0-6.7,5.8-10.7,17.5h-0.4c-0.4-8.1-1.8-12.1-4-12.1h-1.8c-1.3,0-1.8,1.8-1.8,5.8v2.2
                    c0,2.2,0.4,4,0.9,6.3c-10.3,2.7-16.6,4-18.3,4h-2.2c-1.3-0.4-2.2-1.8-2.2-3.1V51c0-4,3.1-8.5,9.4-12.5v0.4
                    c-3.1,5.4-4.9,8.5-4.9,9.8v0.4l3.1,2.7h1.8c1.3,0,1.8-0.9,1.8-3.1c3.1-4.9,4.9-8.1,4.9-9.8v-2.7c-1.3-2.2-2.7-3.1-4.9-3.1h-2.2
                    l-2.7,0.4c-8.5,5.8-13,11.2-13,16.6v1.8c0,4.5,3.1,7.2,9.4,8.1h4c6.7-0.9,12.5-2.7,17-5.8c0.9,2.2,2.2,4.5,4,5.8h1.3l4-0.4l9-20.1
                    l2.2,0.4c0.9,0,1.3-0.9,2.2-2.7C348.2,34,346.4,32.2,342.4,31.8z"/>
                <path d="M415.3,29.5v-1.8l2.7-0.9l4.5-6.7h-1.3v-0.4h1.3l0.4,0.4c1.3-1.3,1.8-2.7,1.8-4v-4c-4.5-7.2-9-10.7-14.3-10.7
                    h-2.7l-0.4,0.4l-0.4-0.4h-0.4c-17.5,3.6-26,11.2-26,22.4c0,3.6,1.3,8.1,3.6,13.4c2.7,6.7,9,12.1,18.8,16.6h1.8
                    c1.3,0,3.1-0.9,4.5-2.2c-1.8-6.7-3.1-13-3.6-18.3l0.4,0.4c16.1,3.6,26.9,8.5,32.2,13.9v0.9c-0.9,1.8-6.3,4.9-15.2,8.5
                    c-9.8,2.2-14.8,3.6-14.8,4l-0.4-0.4l-17,2.2l-2.7-0.4h-1.3c0-0.9-0.4-1.8-1.3-1.8h-0.4l-0.4,0.4l-1.3-4.9h-0.4
                    c-1.8,1.3-3.1,2.7-4,4c1.8,5.4,4.5,8.1,8.5,8.1h0.4c6.7,0,14.8-0.9,24.2-2.2c17-4.5,26.4-8.1,28.2-11.2h2.7l1.8-5.4v-0.9
                    C440.8,40.3,431,34,415.3,29.5z M400.1,45.6c-9.4-2.2-15.2-9.8-16.6-23.3c0-3.6,3.1-7.6,9-11.6l2.2,0.4l4.9-2.7l-1.3,18.3v6.3
                    l1.8,12.1l0,0V45.6z M405,24.2c0.9-2.7,1.8-6.7,2.2-12.1c-1.8-1.3-2.7-3.1-2.7-4.9l2.7-0.4h3.6c3.6,0,6.3,1.3,8.1,4v2.7
                    c0,3.6-4,8.1-12.5,12.5L405,24.2z"/>
                <path d="M486.9,5.8c-0.9-4-1.8-5.8-3.1-5.8h-4c-1.3,0-3.1,9.8-4.5,29.1c0,6.3,0.9,12.5,2.7,18.8c0,0.9,0.4,1.3,0.4,2.2
                    H478c-12.1,3.1-19.2,4.9-21.5,4.9h-2.2c-1.3-0.4-2.2-1.8-2.2-3.1V51c0-4,3.1-8.5,9.4-12.5v0.4c-3.1,5.4-4.9,8.5-4.9,9.8v0.4
                    l3.1,2.7h1.8c1.3,0,1.8-0.9,1.8-3.1c3.1-4.9,4.9-8.1,4.9-9.8v-2.7c-1.3-2.2-2.7-3.1-4.9-3.1H461l-2.7,0.4c-8.5,5.8-13,11.2-13,16.6
                    v1.8c0,4.5,3.1,7.2,9.4,8.1h4c8.1-0.9,14.8-3.6,20.1-8.1c1.3,5.4,4,8.1,7.6,8.1h1.8l0.4-0.4c-4-12.1-5.8-21.9-5.8-30
                    C482.9,23.3,484.2,15.2,486.9,5.8z"/>
                <path d="M497.7,29.1c0-5.8,1.3-13.9,4-23.3c-0.9-4-1.8-5.8-3.1-5.8h-4c-1.3,0-3.1,9.8-4.5,29.1
                    c0,6.3,0.9,12.5,2.7,18.8c1.3,7.6,4,11.6,8.5,11.6h1.8l0.4-0.4C499.4,47,497.7,37.1,497.7,29.1z"/>
            </g>
        </svg>
    )
}

export default Logo;
  