import React from 'react';

const EmailIcon = (props) => {
    let {mainColor, bgColor, height, width} = props;
    return ( 
        <svg  
            xmlns="http://www.w3.org/2000/svg" 
            style={{fill : mainColor}}
            viewBox="0 0 34 32.75"
            height={height}
            width={width}
        > 
            <circle cx="17" cy="16.38" r="16.08"/>
            <path  
                style={{fill: bgColor}} 
                d="M3.9,8v16.2h24.3V8H3.9z M5.4,22.5V9.9l7.2,7.6L5.4,22.5z M13.7,18.6l2.4,2.5l2.4-2.5l7.1,4.2H6.7L13.7,18.6z
             M16.1,19L6.4,9.4h19.4L16.1,19z M19.5,17.5l7.2-7.6v12.6L19.5,17.5z"
            />
        </svg>  
    );
} 
export default EmailIcon;