import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import "../../styles/header.scss";
import ContactInfo from './ContactInfo';
import Logo from '../image-components/Logo';

import {Nav} from './Nav'; 

const Header = ({nav, scrollPage}) => {
    let [scrollPos, upDateScroll] = useState(0);
    const [mounted, updatedMounted] = useState(false)

    useEffect(() => {
        !mounted &&
            setInterval(() => updatedMounted(true), 100)
    })

    document.addEventListener("scroll", () =>{
        upDateScroll(window.pageYOffset)
    });

    return(
        <header style={{
            backgroundPosition: `0 ${scrollPos / 2}px`,
            opacity: "1",
            display: "flex",
            flexDirection: "column"
        }}> 
            <section>
                <div className="content"> 
                    <div className='top-bar'>
                        <Link to='/home' className='logo'>
                            <Logo color="#1dace3" />
                        </Link>
                        <ContactInfo />
                    </div> 
                    <nav>
                        { <Nav nav={nav} scrollPage={scrollPage}/> } 
                    </nav>
                </div>
            </section> 
            <div className={`content opener ${mounted ? 'item-mounted' : ''}`}> 
                <p>Software developer</p>
                <p className='lrg'>
                    <strong>
                        A few projects I've been working on.
                    </strong>
                </p>
                <Link
                    to='/'
                    onClick={(e) => scrollPage(e, true)}>
                        <span>- View projects -</span>
                </Link>
            </div> 
        </header>
    );
}

export default Header;