import React from 'react';
import EmailIcon from '../image-components/EmailIcon';
import PhoneIcon from '../image-components/PhoneIcon';

const classes = 'row j-start a-center p-10 mr-10 accent-blue'
const iconProps = { mainColor: "#db0f56", bgColor: "white", height: "33px", width: "33px" }
const ContactInfo = ({ textClass = 'pl-5' }) => {
    return(
        <ul className='cont'>
            <li className={classes}> 
                <PhoneIcon {...iconProps} /> 
                <span className={textClass} >832-646-6685</span>
            </li>
            <li className={classes}> 
                <EmailIcon {...iconProps} />
                <a className={textClass} href='mailto:me@christopherleebell.com'>me@christopherleebell.com</a>
            </li>
        </ul>
    );
}

export default ContactInfo;