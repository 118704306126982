import React, { useState, useEffect } from 'react';
import '../../styles/component/myGallery.scss';

const ImagesContainer = (props) =>{
    const [containerWidth, updateContainerWidth] = useState(0)

    const {
        baseHeight,
        autoHeight,
        maxHeight,
        images,
        minHeight,
        onClick,
        space=0,
        rounded=0
    } = props

    let calcBaseHeight = baseHeight || 170

    if (!baseHeight && autoHeight) {
        const testHeight = containerWidth / 3
        if (maxHeight && testHeight > maxHeight) {
            calcBaseHeight = maxHeight
        } else {
            calcBaseHeight = testHeight
        }
    }

    if (minHeight && calcBaseHeight < minHeight) {
        calcBaseHeight = minHeight
    }

    calcBaseHeight = `${calcBaseHeight}px`

    const checkContainerSize = () => {
        const container = document.getElementsByClassName('images-container')
        if (container && container[0]) {
            updateContainerWidth(container[0].offsetWidth)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', checkContainerSize)
        checkContainerSize()
        return () => window
            .removeEventListener('resize', checkContainerSize)
    }, [])

    const sharedStyles = {
        borderRadius: `${rounded}px`,
        height: '100%',
        width: '100%',
        calc: '100%'
    }

    const stylesForIndex = index => {

        if (containerWidth <= 600) {
            return {
                float: 'none',
                width: '98%',
                height: calcBaseHeight,
                margin: '5px auto 8px auto'
            }
        }

        switch (index) {
            case 2:
                return ({
                    float: 'right',
                    height: `calc(2 * ${calcBaseHeight} + ${space * 2}px)`,
                    backgroundSize: '220%'
                })
            case 3:
            case 9:
                return ({ width: `calc(100% / 3 * 2 - ${space * 2}px)` })
            case 4:
            case 5:
                return ({ width: `calc(100% / 2 - ${space * 2}px)` })
            case 6:
                return ({
                    height: `calc(2 * ${calcBaseHeight} + ${space * 2}px)`,
                    backgroundSize: '220%'
                })
            default:
                return {}
        }
    }

    const imageList = 
        images.map((img, i) =>{
            img = img.imageTitle ? img : {img, imageTitle: ''}
            const useIndex = i > 12 ? i % 13 : i
            const bg = {
                backgroundImage: `url(${img.cover || img.img})`,
                ...sharedStyles,
                width: `calc(100% / 3 - ${space * 2}px)`,
                margin: `${space}px`,
                height: calcBaseHeight,
                ...stylesForIndex(useIndex)
            }
            return(
                <div
                    key={i}
                    className={`image-item`}
                    style={bg}
                >
                    <div
                        className='image-cover'
                        style={sharedStyles}
                        onClick={()=> onClick(img)}
                    >   
                        {img.imageTitle && 
                            <p className='image-title'>
                                {img.imageTitle}
                            </p>
                        }
                        <span>View</span>
                    </div>
                </div>
            )
        })
    return(
        <div className={`
                images-container
                ${ containerWidth < 550 ? 'mobile-container' : ''}
        `}>
            { imageList }
            <div className='clear-fix' />
        </div>
    );
} 
export default ImagesContainer;