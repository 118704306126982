import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import './styles/index.scss';
import App from './components/App';
import 'whatwg-fetch';
import Promise from 'promise-polyfill';

if (!window.Promise) {
    window.Promise = Promise;
}

ReactDOM.render(<App /> , document.getElementById('root'));