import React from 'react'
import '../../styles/component/myListCard.scss'

const MyListCard = ({ title, listItems, showKeys = true }) => 
    <ul className='my-list-card'>
        <li className='card-title'>{title}</li>
        {
            Object.keys(listItems).map(sub =>
                <React.Fragment key={sub}>
                    {showKeys && <li className='list-item'>{sub}</li>}
                    { listItems[sub].map((subi, index) =>
                        <li key={`${sub}-${index}`}> { subi } </li>)
                    }
                </React.Fragment>
            )
        }
    </ul>

export default MyListCard