import { Home } from '../components/routes/Home'; 
import { Contact } from '../components/routes/Contact';
import { Skills } from '../components/routes/Skills';
// import { About } from '../components/routes/About';

export const nav = [
    {
        path: "/home",
        component: Home,
        display: "Projects"
    },
    {
        path: "/skills",
        component: Skills,
        display: "Skill set"
    },
    // {
    //     path: "/about",
    //     component: About,
    //     display: "About"
    // },
    {
        path: "/contact",
        component: Contact,
        display: "Contact"
    },
];