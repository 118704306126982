import React from 'react'; 
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; 
import "./fade-in.scss";

const FadeIn = (props) =>{ 
    return(
    <ReactCSSTransitionGroup
        transitionName="fade" 
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
    >  
        {props.children}  
    </ReactCSSTransitionGroup>
    )}


export default FadeIn;