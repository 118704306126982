export const BASE_URL = window.location.href.includes(':3000x')
    ? 'http://localhost:8586' : 'https://06designs.com'

export const R_ID = '1613683709517-9-427'    
const KEY_URL = {
    'eKey': '/open/email/test/'
}

const makeCall = async (callData) => {
    const { url, method = 'GET', key, body } = callData
    try {
        const callParams = {
            mode: 'cors',
            method: 'GET'
        }
        // IF no key is set, get key
        let reqKey = localStorage.getItem(key)
        if (key) {
            if (!reqKey || reqKey === '') {
                const keyUrl = KEY_URL[key]
                const resp = await fetch(`${BASE_URL}${keyUrl}`, { ...callParams })
                const respData = await resp.json()
                if (respData && respData.success && respData.key) {
                    reqKey = respData.key
                    localStorage.setItem(key, respData.key)
                }
            }
        }
    
        // SEND PARAMS
        callParams.method = method
        callParams.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        if (body) {
            if (key && reqKey) body.key = reqKey 
            callParams.body = JSON.stringify(body)
        }
        const resp = await fetch(`${BASE_URL}${url}`, callParams)
        const respData = await resp.json()
        return respData
    } catch (err) {
        return { error: true }
    }

}

export default makeCall
