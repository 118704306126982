
import { BASE_URL } from './makeCall'
const mediaUrl = `${BASE_URL}/open/media/a1Api/images/view/`

const newGalleryImage = (data, imgs) => {
  const images = (data.imgs && imgs && imgs[data.imgs]) || []
  let mainImage = ''
  const parsedImages = images.reduce((prev, image) => {
    if (image.tags && image.tags.includes('cover')) {
      mainImage = `${mediaUrl}${image.image}`
    } else {
      prev.push({
        imageTitle: image.title,
        img: `${mediaUrl}${image.image}`,
        cover: `${mediaUrl}${image.thumb}`
      })
    }
    return prev
  }, [])

  return {
    ...data,
    mainImage,
    imgs: parsedImages,
  }
}

const parseGalleryData = (gal, imgs) => {
  const groupedImages = imgs.reduce((prev, img) => {
    const image = { ...img }
    if (image.tags) {
      image.tags = JSON.parse(image.tags)
    }
    if (image.category) {
      if (!prev[image.category]) prev[image.category] = []
      prev[image.category].push(image)
    }
    return prev
  }, {})
  return gal.map(item => newGalleryImage(item, groupedImages))
} 

// const galleryData = [
//     {
//       title: "pfo2 Image gallery ",
//       mainImage: mImg + "img_gal_cover.jpg",
//       descr: "Pfo2 is an image gallery application that allows you to upload images and create tag-based collections to share with other users.",
//       func: [
//         "" 
//       ],
//       extra: "The front end is hosted a sub route of my personal hosting account using Apache. The image data is maintained using the cloudinary API. Authentication, rating data, sharing data and other information is stored using Firebase.",
//       links: [
//         {name: "Live", address: "https://cocky-joliot-0b38b2.netlify.app/"}
//       ],
//       tech: [
//         "React JS", "Firebase", "Cloudinary"
//       ],
//       imgs: [
//         {
//           imageTitle: 'Login/Register screen',
//           img: img + "im_gal1.jpg",
//           cover: cover + "im_gal1.jpg"
//         },
//         {
//           imageTitle: 'Filter + tags',
//           img: img + "im_gal2.jpg",
//           cover: cover + "im_gal2.jpg"
//         },
//         {
//           imageTitle: 'Upload images',
//           img: img + "im_gal3.jpg",
//           cover: cover + "im_gal3.jpg",
//         },
//         {
//           imageTitle: 'Title, label, describe images',
//           img: img + "im_gal4.jpg",
//           cover: cover + "im_gal4.jpg"
//         },
//         {
//           imageTitle: 'Manage shared tags',
//           img: img + "im_gal5.jpg",
//           cover: cover + "im_gal5.jpg"
//         },
//         {
//           imageTitle: 'Sort by year/hearts',
//           img: img + "im_gal6.jpg",
//           cover: cover + "im_gal6.jpg"
//         }
//       ]
//     },

//     {
//       title: "ChatBox",
//       mainImage: mImg + "chatbox.jpg",
//       descr: "ChatBox is a chat server built using NodeJs, Express and vanilla Javascript for the front end. The server uses Socket-IO for active server listening to allow users to interact in real-time.",
//       func: [
//         "The application allows users to log in, log out, set a profile image (with a default fail-safe)",
//         "Users can send messages to the global chatroom and send direct messages"
//       ],
//       extra: "The live version is hosted on Google Cloud Platform",
//       links: [
//         {name: "Github", address: "https://github.com/Obsidian86/sockets"},
//         {name: "Live", address: "https://chat-225515.appspot.com"}
//       ],
//       tech: [
//         "HTML", "CSS", "Javascript", "Nodejs", "Express", "ejs", "Sockets-io"
//       ],
//       imgs: [
//         img + "cbox.gif",
//         img + "cbox_two.gif",
//         img + "cbox_three.gif"
//       ]
//     },



//     {
//       title: "cBook",
//       mainImage: mImg + "cbook.jpg",
//       descr: "This is a checkbook application. The project was developed as a progressive web app. The front-end is built using React JS with Redux for state management. The back-end is built using MongoDB, Express and Node JS. Authentication is handled using Json Web tokens and bcrypt.",
//       func: [
//         "Registering a new user and logging in",
//         "Creating bank accounts for new users to manage",
//         "Adding, editing, deleting transactions for each bank account]"
//       ],
//       extra: "The front-end is hosted on GitHub pages, the backend is hosted on Google App Engine, and the Mongo database is hosted on mLab.",
//       links: [
//         {name: "Github - front", address: "https://github.com/Obsidian86/cbook_frontend"},
//         {name: "Github - back", address: "https://github.com/Obsidian86/cbook-backend"},
//         {name: "Live", address: "https://obsidian86.github.io/cbook_deploy/index.html"}
//       ],
//       tech: [
//         "HTML", "CSS", "Javascript", "React JS", "Redux", "Redux-thunk", "Node JS", "Express", "MongoDB"
//       ],
//       imgs: [
//         {img: img + "cbook_login.gif", imageTitle: "Log in", cover: cover + "cbook_login.gif"},
//         {img: img + "cbook_slide.gif", imageTitle: "Options drawer", cover: cover + "cbook_slide.gif"}, 
//         {img: img + "cbook_new_acc.gif", imageTitle: "Add account", cover: cover + "cbook_new_acc.gif"}, 
//         {img: img + "cbook_new_rest.gif", imageTitle: "Delete account", cover: cover + "cbook_new_rest.gif"}]
//     },


//     {
//       title: "RecipeSite",
//       mainImage: mImg + "recipesite.jpg",
//       descr: "This project consists of several major parts that all work in unison to create a cohesive, useful application. The front-end is built using React JS and React Router for routing. The back-end is built using mongoDB, Express and Node JS. Authentication is handled using Json Web tokens and bcrypt.",
//       links: [
//         {name: "Live", address: "https://romantic-banach-5cd38a.netlify.app"}
//       ],
//       func: [
//         "Registering a new account/ logging in",
//         "Searching the Edamam API for recipes (requires API key)",
//         "Bookmarking/saving recipes for later retrieval ",
//         "A link to the source of the recipe",
//         "Adding items from the ingredients to a shopping list",
//         "Adding/editing/removing your own items from the shopping list",
//         "Sending the shopping list to your email address using sendgrid (requires API key)"
//       ],
//       tech: [
//         "HTML", "CSS", "Javascript", "React JS", "Node JS", "Express", "MongoDB"
//       ],
//       imgs: [
//         {
//           imageTitle: 'Login screen',
//           img: img + "recipe_login.gif",
//           cover: cover + "recipe_login.gif"
//         },
//         {
//           imageTitle: 'Recipe search',
//           img: img + "recipe_search.gif",
//           cover: cover + "recipe_search.gif"
//         },
//         {
//           imageTitle: 'View / Save recipe',
//           img: img + "recipe_save.gif",
//           cover: cover + "recipe_save.gif"
//         },
//         {
//           imageTitle: 'Edit + email list',
//           img: img + "recipe_list.gif",
//           cover: cover + "recipe_list.gif"
//         }
//       ]
//     },

//     {
//       title: "Snake game",
//       mainImage: mImg + "snake.jpg",
//       descr: "This is a response to a coding challenge that I took part in. The challenge was to create a snake-game with a unique twist.",
//       links: [
//         {name: "Github", address: "https://github.com/Obsidian86/snakegame"},
//         {name: "Live version", address: "https://obsidian86.github.io/snakegame"}
//       ],
//       func: [],
//       tech: [
//         "HTML", "CSS", "Javascript"
//       ],
//       imgs: [{
//         img: img + "snake.gif",
//         cover: cover + "snake.gif",
//         imageTitle: 'Game challenge'
//       }]
//     },



//     {
//       title: "LHRE website",
//       mainImage: mImg + "lhre.jpg",
//       descr: "Web design and development for Houston area real estate inspection company. ",
//       extra: "This is a live client site. There may have been changes or the site may have been taken down entirely.",
//       links: [ 
//         {name: "Live version", address: "https://lhreinspections.com"}
//       ],
//       tech: [
//         "PHP", "HTML", "CSS", "Javascript"
//       ],
//       imgs: [{
//         img: img + "lhrefull.jpg",
//         cover: cover + "lhrefull.jpg",
//         imageTitle: 'Client Site'
//       }]
//     },


//     {
//       title: "My Sunscreens website",
//       mainImage: mImg + "mss.jpg",
//       descr: " Web design and development for My Sunscreens. A Solar screen and roll away shades company.",
//       extra: "This is a live client site. There may have been changes or the site may have been taken down entirely.",
//       links: [
//         {name: "Live version", address: "https://www.mysunscreens.com"} 
//       ],
//       tech: [
//         "PHP", "HTML", "CSS", "Javascript"
//       ],
//       imgs: [{
//         img: img + "mss_full_home.jpg",
//         cover: cover + "mss_full_home.jpg",
//         imageTitle: "Client site"
//       }]
//     },

//     {
//       title: "Pet Seek",
//       mainImage: mImg + "petseek.jpg",
//       descr: "This is an HTML/CSS/Javascript project using the Petfinder API to search for pets available for adoption using various parameters.",
//       extra: "The front end is hosted on Github pages, and the back end is hosted on Heroku. Server is set to sleep after 30 minutes- initial load time may take a few seconds.",
//       links: [
//         {name: "Github - Front end", address: "https://github.com/Obsidian86/petseek"},
//         {name: "Github - Back end", address: "https://github.com/Obsidian86/petseek_backend"},
//         {name: "Live version", address: "https://obsidian86.github.io/petseek"}
//       ],
//       tech: [
//         "HTML", "CSS", "Javascript", "Petfinder API", "Node JS"
//       ],
//       imgs: [{
//         img: img + "petseek_full.gif",
//         cover: cover + "petseek_full.gif",
//         imageTitle: 'PeetSeek'
//       }]
//     },

//     {
//       title: "A Great Place to Stay website",
//       mainImage: mImg + "agp.jpg",
//       descr: "Web design and development for Kansas City based pet grooming and boarding company.",
//       extra: "This is a live client site. There may have been changes or the site may have been taken down entirely.",
//       links: [ 
//         {name: "Live version", address: "https://www.agreatplace2stay.com"}
//       ],
//       tech: [
//         "PHP", "HTML", "CSS", "Javascript"
//       ],
//       imgs: [{
//         img: img + "agp2ssite.jpg",
//         cover: cover + "agp2ssite.jpg",
//         imageTitle: 'Client site'
//       }]
//     }
//   ];

export default parseGalleryData
