import React from 'react';
import '../../styles/gallery.scss'; 
import MyListCard from '../my-components/MyListCard';
import ImagesContainer from '../ui/ImagesContainer';

export const Home = ({setViewImage, galleryData}) => {
  const [viewIndex, updateViewIndex] = React.useState(null)
  const [isMounted, updateIsMounted] = React.useState(false)
  const [vImages, updateVImages] = React.useState([])
  const handleUpdatedViewIndex = index => {
    const useVal = viewIndex !== null ? null : index
    if (useVal !== null) {
      const img = galleryData[index]
      updateViewIndex(useVal)
      updateVImages(img.imgs)
      setTimeout(() => { updateIsMounted(true); }, 100)
    } else {
      const selectedItemId = `gal-list-item-${viewIndex}`
      updateIsMounted(false)
      updateVImages([])
      setTimeout(() => { updateViewIndex(useVal) }, 100)
      setTimeout(() => { 
        const item = document.getElementById(selectedItemId)
        item && item.scrollIntoView() 
      }, 500)
    }
  }

  const viewImage = viewIndex !== null
  return (
    <div className="content"> 
      <div className="gallery-container">
        {galleryData.map((img, index) => {
          if (viewImage && index !== viewIndex) return null

          const useButton =
            <button onClick={ () => handleUpdatedViewIndex(index) }>
              { viewImage ? '<- Back to list' : 'View project' }
            </button>

          return (
            <div className="galNode" key={index}>
              <div className='position-scroll-marker' id={`gal-list-item-${index}`} />
              {viewImage &&
              <div className={`top-bar-title w-100 ${isMounted ? 'item-mounted' : ''}`}>
                <span>
                  <button onClick={ () => handleUpdatedViewIndex(index) }>&lt;</button>
                </span>
                <h2>{img.title}</h2>
              </div>}
              <div
                className={`image ${viewImage ? 'item-mounted' : ''}`}
                style={{backgroundImage: `url(${img.mainImage})`}}
              ></div>
              <div className={`info ${isMounted ? 'item-mounted': ''}`}>
                <h2 className={`home-title ${viewImage ? 'item-mounted' : ''}`}>{img.title}</h2>
                <p className="desc">{img.descr}</p>
                {Object.keys(img.links)
                  .map((k)=> <a href={img.links[k]} target="blank" key={k}>{k}</a>)}
                <p><i>{img.extra}</i></p>
                <div className='cloud'>
                  {img.tech.map((t, i)=> <p key={i}>{t}</p> )}
                </div>
                { !viewImage && useButton }
              </div>
              
              <div className={`center pt-30 w-100 img-container ${isMounted ? 'item-mounted' : ''}`}> 
                <ImagesContainer
                  images={vImages}
                  setViewImage={setViewImage}
                  isMounted={isMounted}
                />
              </div>

                {/* DATA ONLY AVAILABLE WHEN VIEWING IMAGE */}
              {
                isMounted &&
                <div className='row content s-top-1 mt-100'>
                  <h3 className='accent-blue mt-100 mb-10 w-100'>
                    Additional information
                  </h3>
                  <div className='w-35 pt-20'>
                    <MyListCard
                      title={'Capabilities'}
                      showKeys={false}
                      listItems={{0 : img.func}}
                    />
                  </div>
                  <div className='mb-20 w-65'>
                    { img.extendedDetails && img.extendedDetails.length > 0 &&
                      <h5 className='t-large m-0'>Retrospective</h5>
                    }
                    {
                      img.extendedDetails && img.extendedDetails.map(para => 
                        <p className='t-space p-5'>{ para }</p>
                      )
                    }
                  </div>
                </div>
              }
            </div>
          );
        })}
        </div>
        { viewImage &&
            <div className='float-action'>
              <button
                onClick={ () => handleUpdatedViewIndex(null) }
              >
                &lt;
              </button>
            </div>
          }
    </div>
  );
}