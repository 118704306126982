import React, {useState, useEffect} from 'react'
import '../../styles/imageViewer.scss'

const ImageViewer = ({viewImage, setViewImage}) => {

    const [imageSize, updateImageSize] = useState({h: 0, w: 0})


    const setImageSize = () => {
      const maxHeight = window.innerHeight - 100
      const maxWidth = window.innerWidth - 20

      const nh = img.naturalHeight
      const nw = img.naturalWidth

      let useWidth = nw > maxWidth ? maxWidth : nw
      let useHeight = nh > maxHeight ? maxHeight : nh

      if(useHeight !== imageSize.h || useWidth !== imageSize.w){
        updateImageSize({h: useHeight, w: useWidth})
      }
    }

    const img = new Image()
    img.src = viewImage.img
    img.onload = () => setImageSize() 

    useEffect(() => {
      window.addEventListener('resize', setImageSize)
      return (() => window.removeEventListener('resize', setImageSize))
    })
      
    const imageStyles = {
      backgroundImage: `url(${viewImage.img})`,
      height: imageSize.h,
      width: imageSize.w
    }

    return(
        <div className="popUpContainer">
            <div className="popUpBg" onClick={()=> setViewImage(null)} />
            <div className="popUpContent" onClick={()=> setViewImage(null)} >
                <div>
                    <div className="popUpControls">
                        <span>{viewImage.imageTitle}</span>
                        <button onClick={()=> setViewImage(null)} className="closeBtn"><span>X</span></button>
                    </div>
                </div>
                <div className="popUpImage" style={imageStyles} />
            </div>
        </div>
    )
}

export default ImageViewer