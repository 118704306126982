import React from 'react';
import MyGallery from '../my-components/MyGallery'

const ImagesContainer = (props) =>{
    const imageList = props.images
    return(
        <MyGallery
            images={imageList}
            onClick={props.setViewImage}
            autoHeight
            maxHeight={200}
            minHeight={130}
            space={props.isMounted ? 5 : 0}
            rounded={props.isMounted ? 5 : 0}
        />
    );
} 
export default ImagesContainer;