import React from 'react';

const PhoneIcon = (props) => {
    let {mainColor, bgColor, height, width} = props;
    return ( 
        <svg  
            xmlns="http://www.w3.org/2000/svg" 
            style={{fill : mainColor}}
            viewBox="0 0 34 32.75"
            height={height}
            width={width}
        > 
            <circle cx="14.2" cy="14.2" r="14.2"/>
            <path fill={bgColor || "white"} d="M8.9,5.6c1.4,0,1.9,0.3,2.3,1.5c0.3,1,0.6,2.1,0.8,3.1c0.1,0.6-0.1,1.1-0.6,1.5c-0.9,0.7-1,1.3-0.5,2.2
                c1.1,1.7,2.6,3.1,4.3,4.3c0.5,0.4,1.1,0.3,1.5-0.1c1-1.1,2.2-1.2,3.5-0.7c0.4,0.1,0.8,0.2,1.2,0.3c1.8,0.5,2.4,2,1.6,3.6
                c-0.9,1.7-2.4,2.1-4.2,2c-1.7-0.1-3.4-0.6-4.8-1.5c-3.4-2-5.9-4.9-7.7-8.3c-1.1-2-1.2-4.1,0-6.2C6.9,6.1,7.8,5.6,8.9,5.6z"/>
            </svg>
    );
} 
export default PhoneIcon; 

