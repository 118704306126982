import React from 'react';

const MenuIcon= (props) => {
    let {mainColor, height, width} = props;
    return (  
        <svg 
            version="1.1" 
            xmlns="http://www.w3.org/2000/svg" 
            x="0px" y="0px" 
            width={width ||"36px"}
            height={height || "36px"} 
            viewBox="0 0 11.9 11.5" 
            style={{fill: mainColor || "white"}}
        > 
            <defs>
            </defs>
            <g>
                <g>
                    <path d="M9.4,2.7H2.5c-0.3,0-0.5,0.2-0.5,0.5v0c0,0.3,0.2,0.5,0.5,0.5h6.9c0.3,0,0.5-0.2,0.5-0.5v0
                        C9.9,2.9,9.7,2.7,9.4,2.7L9.4,2.7z"/>
                    <path d="M9.4,5.2h-7c-0.3,0-0.5,0.2-0.5,0.5v0.1c0,0.3,0.2,0.5,0.5,0.5h7c0.3,0,0.5-0.2,0.5-0.5V5.7
                        C9.9,5.4,9.7,5.2,9.4,5.2L9.4,5.2z"/>
                    <path d="M9.4,7.7h-7C2.1,7.7,1.9,8,1.9,8.2v0.1c0,0.3,0.2,0.5,0.5,0.5h7c0.3,0,0.5-0.2,0.5-0.5V8.2
                        C9.9,8,9.7,7.7,9.4,7.7L9.4,7.7z"/>
                </g>
            </g>
        </svg> 
    );
} 
export default MenuIcon;