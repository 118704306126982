import React from 'react';
import MyListCard from '../my-components/MyListCard'

const parseSkills = items =>
  items
    ? Object.keys(items).map(sk =>
      <MyListCard
        title={sk}
        listItems={items[sk]}
        key={sk}
      />)
    : null

export const Skills = ({ skillsData }) => {
  return (
    <div className="content row pt-30 br-sm">
      <div className='w-35'>
        <div className='accent-blue reverse p-10 br-3 center mt-20 mb-20'>
          <div className='accent-circle'>
            <span>!</span>
          </div>
          <p className='t-space p-5'>
            {skillsData.disclaimer}
          </p>
        </div>
        <span>
          {skillsData && parseSkills(skillsData.other)}
        </span>
      </div>
      <div className='w-65 br-sm'>
        {skillsData && parseSkills(skillsData.devSkills)}
      </div>
    </div>
  )
}
