import React, { useState } from 'react';
import { Nav } from './Nav';
import Logo from '../image-components/Logo';
import MenuIcon from '../image-components/MenuIcon';

const MenuBar = ({ nav, toggle, scrollPage }) => {
    let [hovered, changeHover] = useState(false);
    return (
        <div className="menu-bar">
            <div
                className='iconTainer'
                onClick={() => { toggle("sideBar", null) }}
                style={{ cursor: "pointer", justifySelf: "flex-end" }}
                onMouseEnter={() => changeHover(!hovered)}
                onMouseLeave={() => changeHover(!hovered)}
            >
                <div className="icon" >
                    <MenuIcon mainColor="white" />
                </div>
            </div>
            <div className="logo"><Logo /></div>
            <div className='nav'>
                <Nav nav={nav} scrollPage={scrollPage} />
            </div>
        </div>
    )
}

export default MenuBar;

