import React from 'react';
import {Link, withRouter} from 'react-router-dom'

export const Nav = withRouter(({nav, location, scrollPage}) => { 
    let thisPage = location.pathname === "/" ? "/home" : location.pathname;  
    return(nav.map((nav, key)=> 
        <Link 
            to={nav.path} 
            onClick={()=>scrollPage()}
            key={key} 
            className={ thisPage === nav.path ? "current-page": null}
        >
            { nav.display }
        </Link>
        )
    )})