import React, { useState } from 'react';
import makeCall from '../../data/makeCall';
import ContactInfo from '../ui/ContactInfo';  

export const Contact = () => {
    let initialFormState = {
        name: "",
        email: "",
        phone: "",
        message: "",
    };
    let [formData, changeForm] = useState({...initialFormState});
    let [formSent, changeSent] = useState({
        sent: false,
        status: ""
    });

    const formChange = (event) => {
        changeForm({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    const submitForm = async (event) => {
        event.preventDefault();   
        changeSent({ sent: true, status: "Sending." });

        const dataToSend = {
            "to": "me@christopherleebell.com",
            "from": "contact-form@christopherleebell.com",
            "subject": "New message from contact form",
            "message": `
                "name": ${formData.name || 'None'}<br />
                "email": ${formData.email || 'None'}<br />
                "phone": ${formData.phone || 'None'}<br />
                "message": ${formData.message || 'None'}
            `
        }

        const sendStatus = {
            sent: true,
            status: "Form failed to send."
        }
        const response = await makeCall({
            body: dataToSend,
            method: 'POST',
            key: 'eKey',
            url: '/open/email/test/'
        })
        if (!response.error) {
            sendStatus.status = "Form sent successfully."
            changeForm({...initialFormState})
        }
        
        return changeSent(sendStatus)   
    }

    const fields = [
        { label: 'Name', alias: 'name', placeholder: 'Your Name' },
        { label: 'Email address', alias: 'email', placeholder: 'email@address.com' },
        { label: 'Phone number', alias: 'phone', placeholder: '555-555-5555' },
     ]

    return (
        <div className="content p-10 pb-40">
            <h2>Get in touch</h2>
            <div className='mb-35 t-large'>
                <ContactInfo textClass='pl-10' />
            </div>

            <div>
                <div className='center pt-5 pb-50'>
                    <p>Have a question or comment? Send me a message and I'll get back to you.</p>
                </div>
                <form className='row br-sm' onSubmit={(event) => { submitForm(event) }} >
                    <div className='w-45'>
                        {
                            fields.map(f => 
                                <React.Fragment key={f.alias}>
                                    <label htmlFor={f.alias}>{ f.label }</label>
                                    <input type='text' placeholder={f.placeholder} name={f.alias} id={f.alias} onChange={formChange} value={formData[f.alias]} />
                                </React.Fragment> )
                        }
                    </div>
                    <div className='w-55' style={{ display: "flex", flexDirection: "column" }}>
                        <label htmlFor="name">Message</label>
                        <textarea type='text' placeholder='Your message here' name="message" id="message" style={{ flex: "auto" }} onChange={(event) => formChange(event)}  value={formData.message}>
                        </textarea>
                    </div>
                    <div className='w-100 right'>
                        <p style={{fontSize: "1.2rem", color: "red", fontWeight: "bold", display: formSent ? "block" : "none"}}>{formSent.status}</p>
                        <button className="button-form">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}