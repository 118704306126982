import React from 'react';
import {Nav} from './Nav'; 
import Logo from '../image-components/Logo';
import ContactInfo from './ContactInfo';
import "../../styles/sidebar.scss";

const SideBar = ({nav, toggle, scrollPage}) => { 
    return( 
        <div className="side-bar">
            <button onClick={()=>toggle("sideBar", null)}>X</button >
            <div className="logo">
                <Logo color={"#1dace3"}/>
            </div>
            <span className="nav">
                <Nav nav={nav} scrollPage={scrollPage}/>
            </span>
            <br /><br />
            <ContactInfo /> 
        </div>
    );
}

export default SideBar;